<!-- <div class="container-lg footer">
    <p>CarShopping &copy; 2021</p>
</div>
 -->

<div class="container-lg footer">
    <p>CarShopping &copy; 2021 &nbsp;  
            <a id="btnFacebook" href="https://www.facebook.com/carshoppingautos/" target="_blank">
                    <img src="../../../../assets/images/footer/Facebook.png" width="35px" alt="Facebook">
            </a>  &nbsp; 
            <a id="btnWhatsapp" [href]="this.whatsapp" target="_blank">
                    <img src="../../../../assets/images/footer/Whatsapp.png" width="25px" alt="Whatsapp">   
            </a>
    </p>
</div>