import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FrequentQuestionsComponent } from './components/frequent-questions/frequent-questions.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { WhereWeAreComponent } from './components/where-we-are/where-we-are.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/Home',
        pathMatch: 'full'
      },
      {
        path: 'Home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'QuienesSomos',
        component: AboutUsComponent
      },
      {
        path: 'PreguntasFrecuentes',
        component: FrequentQuestionsComponent
      },
      {
        path: 'DondeEstamos',
        component: WhereWeAreComponent
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
