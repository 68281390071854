<app-nav-bar></app-nav-bar>
<div class="container">
    <router-outlet>
    <link rel="stylesheet" href="layout.component.css">

  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
  <a [href]="this.whatsapp" class="float" target="_blank">
  <i class="fa fa-whatsapp my-float"></i>
  </a>
    
    </router-outlet>
</div>
<app-footer></app-footer>