import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {
  public urlBase: string;

  constructor(private http: HttpClient) {
    this.urlBase = environment.urlBaseServicio;
   }

   getWhatsappNumber(){
    const url = `${this.urlBase}SystemSetting/GetWhatsappValue`;
    return this.http.get(url);
  }
   
}
