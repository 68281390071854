<div class="row d-flex justify-content-center">
  <h1 class="text-muted">¿Quiénes Somos?</h1>
</div>
<div class="row">
    <div class="col-sm-12 col-lg-5">
      <img class="img-fluid img-thumbnail"
        src="assets/images/aboutus/mission.jpg" >
      </div>
  <div class="col-sm-12 col-lg-7">
    <div span class="mission">
        <h1 class="text-muted">Misión</h1>
        <p >Onec gravida ante velit, a bibendum odio feugiat ac. Sed sapien ligula, tristique a dui at, commodo mollis velit. Vestibulum eu lorem nisi. Nulla magna odio, hendrerit ultricies bibendum id, hendrerit et est. Sed ornare, sapien quis ultrices semper, urna massa bibendum magna, et accumsan mauris nisi a lorem. Nullam vulputate, lectus eget lobortis laoreet, tellus orci venenatis ante, sed egestas dolor felis id tellus. Phasellus lacus tortor, aliquet sed auctor a, eleifend nec est. Proin at lobortis urna. Curabitur sed neque in purus interdum vestibulum. Ut pretium risus sem, facilisis gravida mi auctor id. Integer tincidunt ornare mollis.</p>
    </div>
  </div>
</div>
<div class="row flex-column-reverse flex-lg-row">
    <div class="col-sm-12 col-lg-7">
        <div span class="vision">
            <h1 class="text-muted">Visión</h1>
            <p>Onec gravida ante velit, a bibendum odio feugiat ac. Sed sapien ligula, tristique a dui at, commodo mollis velit. Vestibulum eu lorem nisi. Nulla magna odio, hendrerit ultricies bibendum id, hendrerit et est. Sed ornare, sapien quis ultrices semper, urna massa bibendum magna, et accumsan mauris nisi a lorem. Nullam vulputate, lectus eget lobortis laoreet, tellus orci venenatis ante, sed egestas dolor felis id tellus. Phasellus lacus tortor, aliquet sed auctor a, eleifend nec est. Proin at lobortis urna. Curabitur sed neque in purus interdum vestibulum. Ut pretium risus sem, facilisis gravida mi auctor id. Integer tincidunt ornare mollis.</p>
        </div>
    </div>
    <div class="vision col-sm-12 col-lg-5 ">
        <img class="img-fluid img-thumbnail"
        src="assets/images/aboutus/vission.jpg">
    </div>
</div>
<h1 class="text-muted">Servicios</h1>
 
<div class="row">
  <div class="col sm-12 col-md-12 col-lg-5 card text-white">
    <img src="assets/images/aboutus/engine.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <h3 class="card-title">Financiación</h3>
      <p class="card-text">Onec gravida ante velit, a bibendum odio feugiat ac. Sed sapien ligula, tristique a dui at, commodo mollis velit. Vestibulum eu lorem nisi. Nulla magna odio, hendrerit ultricies bibendum id, hendrerit et est. Sed ornare, sapien quis ultrices semper, urna massa bibendum magna, et accumsan mauris nisi a lorem. Nullam vulputate, lectus eget lobortis laoreet, tellus orci venenatis ante, sed egestas dolor felis id tellus. Phasellus lacus tortor, aliquet sed auctor a, eleifend nec est. Proin at lobortis urna. Curabitur sed neque in purus interdum vestibulum. Ut pretium risus sem, facilisis gravida mi auctor id. Integer tincidunt ornare mollis. Onec gravida ante velit, a bibendum odio feugiat ac. Sed sapien ligula, tristique a dui at, commodo mollis velit. Vestibulum eu lorem nisi. Nulla magna odio, hendrerit ultricies bibendum id, hendrerit et est.</p>
      </div>
  </div>
  <div class="col-lg-2 "></div>
  <div class="col sm-12 col-md-12 col-lg-5 card text-white ">
    <img src="assets/images/aboutus/gears.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <h3 class="card-title">Trámites</h3>
      <p class="card-text">Onec gravida ante velit, a bibendum odio feugiat ac. Sed sapien ligula, tristique a dui at, commodo mollis velit. Vestibulum eu lorem nisi. Nulla magna odio, hendrerit ultricies bibendum id, hendrerit et est. Sed ornare, sapien quis ultrices semper, urna massa bibendum magna, et accumsan mauris nisi a lorem. Nullam vulputate, lectus eget lobortis laoreet, tellus orci venenatis ante, sed egestas dolor felis id tellus. Phasellus lacus tortor, aliquet sed auctor a, eleifend nec est. Proin at lobortis urna. Curabitur sed neque in purus interdum vestibulum. Ut pretium risus sem, facilisis gravida mi auctor id. Integer tincidunt ornare mollis. Onec gravida ante velit, a bibendum odio feugiat ac. Sed sapien ligula, tristique a dui at, commodo mollis velit. Vestibulum eu lorem nisi. Nulla magna odio, hendrerit ultricies bibendum id, hendrerit et est.</p>
      </div>
  </div>
</div> 