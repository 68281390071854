<div class="row d-flex justify-content-center">
  <div class="col-12 col-sm-12">
    <h1 class="title text-muted">¿Preguntas Frecuentes?</h1>
  </div>  
</div>
<!-- boostrap AccordionCollapse FrecuentQuestions-->  
<div class="accordion" id="AccordionAll">
    <!-- Question1 -->
    <div class="card">
      <div class="card-header" id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            ¿Cuánto tiempo demora el pago de mi vehículo?
          </button> 
        </h2>
      </div>
      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#AccordionAll">
        <div class="card-body">
          Nuestra propuesta de valor contempla que una vez acordado el precio del vehículo en máximo una hora el vendedor tendrá el dinero en su cuenta y nosotros la propiedad del vehículo.
        </div>
      </div>
    </div>
    <!-- Question2 -->
    <div class="card">
      <div class="card-header" id="headingTwo">
        <h2 class="mb-0">
          <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            ¿Puedo vender un vehículo el cual no se encuentra a mi nombre?
          </button>
        </h2>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#AccordionAll">
        <div class="card-body">
          En nuestro país es legal vender bienes de terceros con autorización, dicho esto si se cuenta con un poder amplio y suficiente autenticado en el que el propietario del vehículo autoriza para hacer el traspaso y recibir los recursos de la venta del mismo, la respuesta es sí, si puedes vender un vehículo que no es de tu propiedad.
        </div>
      </div>
    </div>
    <!-- Question3 -->
    <div class="card">
      <div class="card-header" id="headingThree">
        <h2 class="mb-0">
          <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            ¿Comprarían mi auto si debo impuestos de años anteriores?
          </button>
        </h2>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#AccordionAll">
        <div class="card-body">
          Sí, compramos tu vehículo si debe impuestos. Lo único a tener en cuenta es que en la liquidación de impuestos de los años que debas se debe pagar en muchos casos una multa más intereses, estos valores serán descontados de la oferta final de tu vehículo.
        </div>
      </div>
    </div>
    <!-- Question4 -->
    <div class="card">
        <div class="card-header" id="headingFour">
          <h2 class="mb-0">
            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              El vehículo que quiero vender no tiene SOAT ni revisión técnico mecánica vigente, ¿Qué puedo hacer para venderlo?
            </button>
          </h2>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#AccordionAll">
          <div class="card-body">
            Para venderlo no tendrías ningún problema, nuestro equipo hará el peritaje a domicilio y de llegar a un acuerdo recogeríamos el vehículo en una de nuestras grúas dispuestas para tal fin.
          </div>
        </div>
      </div>
      <!-- Question5 -->
      <div class="card">
        <div class="card-header" id="headingFive">
          <h2 class="mb-0">
            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                ¿Quién realiza la documentación y traspaso?
            </button>
          </h2>
        </div>
        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#AccordionAll">
          <div class="card-body">
            <p>Nosotros nos encargamos de estos trámites engorrosos para que tu proceso sea más cómodo y ágil. 
              <br />Realización del trámite directamente con las entidades de tránsito oficiales: 
            </p>
            <ul>
              <li>Servicios Integrales para la Movilidad (SIM)</li>
              <li>Secretarías de Tránsito en todos los municipios de Cundinamarca</li>
              <li>Secretaria de Hacienda</li>
              <li>Secretaria de Movilidad</li>
              <li>Entre otros.</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Question6 -->
      <div class="card" id="CardStyle">
        <div class="card-header" id="headingSix">
          <h2 class="mb-0">
            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              ¿Por qué no encuentro mi vehículo en el listado del formulario?
            </button>
          </h2>
        </div>
        <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#AccordionAll">
          <div class="card-body">
            Nuestra base de datos contiene una amplia selección de vehículos nacionales e internacionales. Sin embargo, puede que alguno nos falte. En el caso de que no encuentres tu vehículo en nuestra base de datos, te puedes contactar con nuestros asesores que con gusto te atenderán.
          </div>
        </div>
      </div>
  </div>
