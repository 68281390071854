import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.css']
})
export class FrequentQuestionsComponent implements OnInit {
  public title:string = "Preguntas Frecuentes"
  constructor() { }

  ngOnInit(): void {
  }

}
