<!-- <div class="row d-flex justify-content-center">
    <div class="col-12 col-sm-12">
      <h1 class="title text-muted">¿Donde Estamos?</h1>
    </div>  
</div> -->

<div class="row">
    <div class="col">
        <h3 class="office-title">Oficinas</h3>
    </div>
</div>

<div class="container">
 <div class="row ">
     <!-- <div class="col">
                <p class="title">
                Calle 98 # 70 - 91, Centro Empresarial Vardi, Bogotá - Colombia
                </p>
      </div> -->
 </div>
 <div class="row">
     <div class="col " >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.4383139700703!2d-74.08077858513839!3d4.693649696590768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b2742e4667b%3A0x1257b733d1368318!2sEdificio%20VARDI%20-%20FEDEPALMA%2C%20Centro%20Empresarial%20Pontevedra!5e0!3m2!1ses!2sco!4v1620358213225!5m2!1ses!2sco" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
     </div>
     
     
     
    <div class="container">
        <div class="row ">   
            <div class="col-sm-12 col-lg-6 ">
                <br>
               <h5 class="title">Teléfonos</h5>
               <a href="tel:+573193069242">+57 3193069242</a>
               
            </div>
            <div class="col">
                <br>
               <h5 class="title email-title">Correos electrónico</h5>
               <a href="mailto:servicioalcliente@carshoppingcompras.com">servicioalcliente@carshoppingcompras.com</a>
               <br>
               <a href="mailto:compras@carshoppingcompras.com">compras@carshoppingcompras.com</a> 
            </div>
       </div>
    </div>
    
  
 </div>
</div>
