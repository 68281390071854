import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SystemSettingsService } from 'src/app/services/system-settings.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  providers: [
    SystemSettingsService
  ]
})
export class LayoutComponent implements OnInit {
  whatsapp: string;

  constructor(
    private systemSettingsService: SystemSettingsService
  ) { }

  ngOnInit(): void {
    this.systemSettingsService.getWhatsappNumber().subscribe((res: any) => {
      let number = res.data;
      this.whatsapp = `https://api.whatsapp.com/send?phone=${number}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20CarShopping%20`;
    }, (error: HttpErrorResponse) => {

    });
  }

}
